.modal-artist-style {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}
.modal-poster-style {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}
